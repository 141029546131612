<template>
	<div class="serial-search">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<div class="progress">
				<v-progress-circular
					:size="100"
					color="primary"
					indeterminate/>
			</div>
		</div>

		<!-- Serial Search Input Section -->
		<div class="serial center">
			<div class="search center">
				<v-combobox
					class="fields"
					v-model="serial"
					:items="serials"
					ref="serialInput"
					label="Serial Number"
					hide-details="auto"
					outlined
					dense
					clearable
					@keyup.enter.native="getResults"/>
			</div>

			<div class="search-button center">
				<v-btn
					class="button"
					color="primary"
					:disabled="!canSearch"
					@click="getResults">
					Search
				</v-btn>
				
				<div
					style="margin-left: 10px;"
					v-if="results.length > 0">
					<v-btn
						color="success"
						@click="copyLink()">
						Copy Link
					</v-btn>
				</div>

				<div
					style="margin-left: 10px;">
					<v-btn
						color="teal"
						dark
						@click="showUpdate()">
						Help
					</v-btn>
				</div>

				<div style="margin-left: 10px;">
					<span 
						v-if="error.length > 0"
						style="font-size: smaller;color: red;"
						v-html="error">
					</span>
				</div>
			</div>

			<div class="decoder center">
				<SerialDecoder ref="decoder"/>
			</div>
		</div>

		<!-- Searial Search Results Section -->
		<div class="serial-main"
			:style="background">
			<div class="main-container"
				v-if="results.length > 0">
				<div class="top-expand">
					<div class="expand-all-button">
						<v-btn
							small
							color="light-blue lighten-5"
							@click="expandAll">
							Expand All
						</v-btn>
					</div>

					<div class="expand-all-button">
						<v-btn
							small
							color="light-blue lighten-5"
							@click="collapseAll">
							Collapse All
						</v-btn>
					</div>
				</div>
				
				<div class="notes"
					v-if="notes.length > 0">
					<span class="title">Unit Notes:</span>

					<v-divider />

					<span class="pre-formatted">{{ this.notes }}</span>
				</div>
				<!-- Process Categories -->
				<div class="category elevation-3"
					v-for="category, index in filteredCategories"
					:key="index">

					<!-- Iterate and create each category -->
					<template v-if="category.results.length > 0">
						<div 
							class="category-title"
							@click="category.visible = !category.visible">
							<div class="expand-button">
								<v-btn
									v-if="!category.visible"
									color="blue-grey lighten-4"
									x-small
									@click.stop="category.visible = true">
									<v-icon dark>mdi-plus</v-icon>
								</v-btn> 
								
								<v-btn
									v-if="category.visible"
									color="blue-grey lighten-4"
									x-small
									@click.stop="category.visible = false">
									<v-icon dark>mdi-minus</v-icon>
								</v-btn> 
							</div>

							<div class="expand-button">
								<h3> {{ category.name }}</h3>
							</div>                        
						</div>

						<!-- Display category results panel -->
						<v-expand-transition v-show="category.visible">
							<div 
								class="results"
								v-if="category.visible">
								<!-- Display Category Header -->
								<div class="result-row elevation-6" style="background-color: #b3f0ff; border: 1px solid darkgray;">
									<div class="result-line">
										<div class="result-part centered">
											<h4>Part Number</h4>
										</div>

										<div class="result-quantity centered">
											<h4>Quantity</h4>
										</div>
										
										<div class="result-description centered">
											<h4>Description</h4>
										</div>
										
										<div class="result-edited centered">
											<h4>Last Edited</h4>
										</div>
									</div>
								</div>

								<div 
									class="top-level elevation-6"
									v-for="item, index in category.results"
									:key="item.part + '_' + index">
									<component
										:is="'Result'"
										:item="item"
										:level="0"
										:nextLevel="1">
									</component>
								</div>
							</div>
						</v-expand-transition>
					</template>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
import axios from 'axios'							// Axios is used for http async/promise api functionality.

import SerialDecoder from './serial_decoder.vue'	// Imports the serial number decoder component displayed in top right of serial search.
import Result from './result'						// Imports result component used to display parts and bom results.

export default {
	name: 'SerialSearch',
	components: {
		SerialDecoder,
		Result,
	},
	props: {
	},
	data() {
		return {
			serial: "",         		// Bound to serial input.
			serials: [],				// Holds previous serial search results
			results: [],        		// Holds current API search results.
			categories: [       		// Sorting categories. Contains category name, category filters and category specific results
				{ 
					name: "Auger",
					filters: "AUGERS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Conveyor",
					filters: "CONVEYORS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Decals",
					filters: "DECALS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Driveline",
					filters: "DRIVELINES",
					results: [],
					visible: false, 
				},
				{ 
					name: "Electrical",
					filters: "ELECTRICAL",
					results: [],
					visible: false, 
				},
				{ 
					name: "Filters & Oil",
					filters: "FILTERS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Final Drive",
					filters: "FINALDRIVES",
					results: [],
					visible: false, 
				},
				{ 
					name: "Frame Parts",
					filters: "FRAMES",
					results: [],
					visible: false, 
				},
				{ 
					name: "Hydraulics",
					filters: "HYDRAULICS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Manuals",
					filters: "MANUALS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Platform",
					filters: "PLATFORMS",
					results: [],
					visible: false, 
				},
				{ 
					name: "PTO",
					filters: "PTOS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Scale",
					filters: "SCALES",
					results: [],
					visible: false, 
				},
				{ 
					name: "Second Cutter",
					filters: "SECONDCUT",
					results: [],
					visible: false, 
				},
				{ 
					name: "Tires & Wheels",
					filters: "TIRES/WHEELS",
					results: [],
					visible: false, 
				},
				{ 
					name: "Tub",
					filters: "TUB",
					results: [],
					visible: false, 
				}, 
				{ 
					name: "Other",
					filters: "OTHER",
					results: [],
					visible: false, 
				}
			],
			notes: "",					// Unit specific Notes

			error: "",					// Error variable used to display errors to user.
			loading: false,				// Boolean to toggle visual loading components.
		}
	},
	methods: {
		getResults() {
			this.results = []
			this.loading = true;
			this.serial = this.serial.trim().toUpperCase()
			this.notes = ""
			this.error = ""

			this.collapseAll()
			this.clear()

			var formData = new FormData();

			axios.get(`https://dealerapi.jcwebprod.com/api.php?cmd=getUnit&serial=${this.serial}`)
				.then(response => {
					// API FAILED
					if (!response.data.success){
						this.loading = false;

						this.error = "* No results: Serial may be incorrect, incomplete or unavailable."

						this.$refs.decoder.clear()
						return
					}

					this.clear()
					this.results = response.data.results
					this.notes = response.data.notes
					this.loading = false
					this.$refs.decoder.decode(this.serial)
					this.sortResults()
					
					if (!this.serials.includes(this.serial)){
						this.serials.unshift(this.serial)
						
						if (this.serials.length > 10){
							this.serials = this.serials.slice(0, 10)
						}
					}

					this.$refs.serialInput.blur()
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message);
					}

					console.log(error.config);            
				})
		},
		sortResults() {
			// Iterate through all results first
			for(var result of this.results){
				var matched = false     // Matched variable set when a match is made. Used to stop matching and to determine if result needs to go into other.
				// Iterate through categories to get category specific filters
				for(var category of this.categories){
					if(result.category == category.filters){
						category.results.push(result)

						matched = true
					}
				}

				// If after all itteration on that result is complete and it hasn't been matched, push it into "Other" category
				if (matched == false){
					this.categories[this.categories.length - 1].results.push(result)
				}

				// If a match was found, we need to reset the match boolean before checking the next result
				matched = false
			}
		},
		clear(){
			// Clear all categories individual results
			this.categories.forEach(function(category){
				category.results = []
			})

			// Clear the API results
			this.results = []
		},
		expandAll() {
			for(var c in this.categories){
				this.categories[c].visible = true
			}
		},
		collapseAll(){
			for(var c in this.categories){
				this.categories[c].visible = false
			}
		},
		copyLink() {
			var link = `${this.$dealer}?serial=${this.serial}`

			navigator.clipboard.writeText(link)

			alert(`Link to unit created and copied to clipboard`)
		},
		showUpdate() {
			this.$emit('showUpdate', true)
		},
		partClicked(id, part) {
			if (part.length > 0){
				//this.partNumber = part

				//this.searchPart(id)
			}
		},
	},
	computed: {
		canSearch() {
			if (this.serial.length >= 6){
				return true
			}

			return false
		},
		filteredCategories() {
			var filtered = []
			for(var category of this.categories){
				if(category.results.length > 0){
					filtered.push(category)
				}
			}

			return filtered
		},
		background() {
			if (this.results.length == 0) {
				return ""
			}

			return "background-color: white; border: 2px solid darkgrey;"
		}
	},
	mounted: function() {
		let urlParams = new URLSearchParams(window.location.search)

		if(urlParams.has('serial')){
			this.serial = urlParams.get('serial')

			if(this.serial.length > 0){
				this.getResults()
			} else {
				console.log(`Invalid value for serial parameter: ${this.serial}`)
				this.serial = ''
			}
		}
	},	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.serial-search {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.top-level {
	margin-top: 5px;
}

.serial-main {
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	height: 100%;
	width: 100%;
	padding: 5px;
	overflow-x: hidden;
	overflow-y: auto;
}

.serial {
	display: flex;
	flex-flow: row;
	width: 100%;
	flex-shrink: 1;
	border: 1px solid silver;
	background-color: white;
	padding: 5px;
	margin-bottom: 5px;
}

.category {
	width: 100%;
	flex-shrink: 1;
	border: 1px solid silver;
	padding: 5px;
	margin-bottom: 5px;    
	background-color: whitesmoke;
}

.search-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
	padding-left: 5px;
	padding-right: 5px;
}

.decoder {
	flex-shrink: 1;
}

.result-row {
	display:flex;
	flex-direction: column;
	width: 100%;
	min-height: 25px;
	font-weight: 600;
}

.result-line {
	display:flex;
	flex-direction: row;
	width: 100%;
	min-height: 25px;
	background-color: 80b3ff;
}

.result-part {
	width: 20%;
	border: 1px solid #4d4d4d;
	padding: 5px;
}

.result-quantity {
	width: 5%;
	border: 1px solid #4d4d4d;
	padding: 5px;
}

.result-description {
	width: 60%;
	border: 1px solid #4d4d4d;
	padding: 5px;
}

.result-edited {
	width: 15%;
	border: 1px solid #4d4d4d;
	padding: 5px;
}

.centered {
	text-align: center;
}

.title {
	flex-shrink: 1;
	font-size: 24pt;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.loading {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(0,0,0,.25);
	height: 100%;
	width: 100%;
	text-align: center;
}

.progress {
	position: relative;
	top: 40%;
}

.discontinued {
	color: red;
}

.category-title {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
}

.expand-button {
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-left: 5px;
}

.expand-all-button {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.top-expand {
	display: flex;
	flex-direction: row;
	padding-right: 5px;
	padding-bottom: 5px;
}

.parts-line {
	display: flex;
	flex-direction: column;
}

.notes {
	border: 1px solid silver;
	border-radius: 5px;
	padding: 5px;
	background-color: blanchedalmond;
	margin-bottom: 5px;
	margin-top: 5px;
}

.pre-formatted {
	white-space: pre-wrap;
}

</style>